import * as styles from './affiliates.module.scss';

import React, { useState } from 'react';
import { faMinus, faPlus } from "@fortawesome/pro-light-svg-icons"

import Button from '../components/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footer from '../components/v2/footer';
import Header from '../components/v2/header';
import SEO from '../components/seo';
import Section from '../components/section';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';

const AffiliatesPage = ({ data }) => {
  return (
    <>
      <SEO
        title="Referral Program"
        description="Boost your income with Interact's Referral Program. Join our innovative quiz-powered platform and unlock lucrative passive revenue streams through strategic affiliate partnerships."
      />
      <Header />
      <main className={ styles.affiliates }>
        <Section theme="white" style={ { marginTop: `4.5rem` } }>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8 text-center">
              <h1 className="heading-xl color-black">Referral Program</h1>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-6 text-center">
              <h2 className="text-body color-muted-foreground text-center" style={{ marginBottom: '0' }}>Elevate your affiliate marketing strategy with our innovative quiz-powered solution. Unlock new revenue streams and maximize your passive income potential.</h2>
            </div>
          </div>
        </Section>
        <Section theme="grey">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 col-xl-8 text-center">
              <p className="heading-xs-upper color-pink" style={{ fontSize: '14px', marginBottom: '1rem' }}><strong>Overview</strong></p>
              <p className="heading-lg" style={{ color: '#030712', marginBottom: `2.5rem` }}>At Interact, we believe in growing together.<br/>That's why we've worked hard to build a successful referral program for you.</p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8 col-xl-6 text-center">
              <p className="heading-sm color-black">Our program is designed to pay you cash upfront when customers you sign up become paying customers of ours.</p>
              <p className="heading-sm color-black"><strong>And we pay you $20 for every qualifying customer that subscribes to the platform, regardless of their plan.</strong></p>
              <p className="heading-sm color-black">Email affiliates@tryinteract.com for more information.</p>
              <Button theme="primary" size="large" href={ `https://interact.partnerstack.com/?group=referralpartnersoptin` } style={ { marginTop: `2rem` } }>Sign up today</Button>
            </div>
          </div>
        </Section>
        <Section theme="white">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8 text-center">
              <h1 className="heading-xl color-black">How to become an affiliate</h1>
            </div>
          </div>
          <div className="row justify-content-center" style={{ marginTop: '3rem' }}>
            <div className="col-12 col-lg-6">
              <div className={ styles.card }>
                <div className={ styles.badge }>1</div>
                <div className="d-flex flex-column align-items-start">
                  <p className="heading-sm color-black"><strong>Sign up</strong></p>
                  <p className="text-body color-muted-foreground">Click the button above to visit our Referral Program information page.</p>
                </div>
              </div>
              <div className={ styles.card }>
                <div className={ styles.badge }>2</div>
                <div className="d-flex flex-column align-items-start">
                  <p className="heading-sm color-black"><strong>Create Partnerstack account</strong></p>
                  <p className="text-body color-muted-foreground">Click the "Join Now" button in the top right hand corner and create your Partnerstack account.</p>
                </div>
              </div>
              <div className={ styles.card }>
                <div className={ styles.badge }>3</div>
                <div className="d-flex flex-column align-items-start">
                  <p className="heading-sm color-black"><strong>Retrieve your link</strong></p>
                  <p className="text-body color-muted-foreground">After accessing your account, ensure you've accepted the terms and conditions. Navigate to the "Links" section in the sidebar to retrieve your unique referral URL. Pro tip: You can create multiple links directing to Interact's platform, all of which will be credited to your account.</p>
                </div>
              </div>
              <div className={ styles.card }>
                <div className={ styles.badge }>4</div>
                <div className="d-flex flex-column align-items-start">
                  <p className="heading-sm color-black"><strong>Set up payment method</strong></p>
                  <p className="text-body color-muted-foreground">Configure your preferred payment method (PayPal or Stripe) for seamless payouts, and access our brand kit for marketing assets and logos to enhance your promotional efforts.</p>
                </div>
              </div>
              <div className={ styles.card }>
                <div className={ styles.badge }>5</div>
                <div className="d-flex flex-column align-items-start">
                  <p className="heading-sm color-black"><strong>Promote</strong></p>
                  <p className="text-body color-muted-foreground">Launch your promotional campaign across multiple channels. Leverage social media platforms, create engaging blog content, integrate with your website, and explore other digital marketing avenues to maximize your reach and conversions.</p>
                </div>
              </div>
            </div>
          </div>
        </Section>
        <Section theme="grey">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 col-xl-8 text-center">
              <p className="heading-xs-upper color-pink" style={{ fontSize: '14px', marginBottom: '1rem' }}><strong>Support</strong></p>
              <p className="heading-lg" style={{ color: '#030712', marginBottom: `2.5rem` }}>Empowering our affiliates with a streamlined, high-converting referral process is at the core of Interact's affiliate ecosystem.</p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8 col-xl-6">
              <p className="heading-sm color-black text-center">What we provide to our affiliates:</p>
              <ul style={{ borderTop: '1px solid rgb(229 231 235)'  }}>
                <li>
                  <div className={ styles.check }><FontAwesomeIcon icon={ faCheck }/></div>
                  <div className="d-flex flex-column align-items-start">
                    <p className="text-body color-black"><strong>Onboarding</strong></p>
                    <p className="text-body color-muted-foreground">Get to know the software inside and out and understand how to implement quizzes.</p>
                  </div>
                </li>
                <li>
                  <div className={ styles.check }><FontAwesomeIcon icon={ faCheck }/></div>
                  <div className="d-flex flex-column align-items-start">
                    <p className="text-body color-black"><strong>Press Kit</strong></p>
                    <p className="text-body color-muted-foreground">Learn exactly what to say to potential customers with easy-to-copy email templates, social templates, logos, and SEO hot words.</p>
                  </div>
                </li>
                <li>
                  <div className={ styles.check }><FontAwesomeIcon icon={ faCheck }/></div>
                  <div className="d-flex flex-column align-items-start">
                    <p className="text-body color-black"><strong>Support</strong></p>
                    <p className="text-body color-muted-foreground">Access to support channel through Partnerstack, Live Chat, or Email. Access to help documentation specialized to help you serve your clients. Schedule a call with our program manager.</p>
                  </div>
                </li>
                <li>
                  <div className={ styles.check }><FontAwesomeIcon icon={ faCheck }/></div>
                  <div className="d-flex flex-column align-items-start">
                    <p className="text-body color-black"><strong>Pricing and Plans</strong></p>
                    <p className="text-body color-muted-foreground">Know the plans and have access to documentation outlining available functionality in each plan.</p>
                  </div>
                </li>
                <li>
                  <div className={ styles.check }><FontAwesomeIcon icon={ faCheck }/></div>
                  <div className="d-flex flex-column align-items-start">
                    <p className="text-body color-black"><strong>Newsletters</strong></p>
                    <p className="text-body color-muted-foreground">Monthly newsletters with program/company updates, new material to sell and promote, and reminders to check stats and analytics for your sales.</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </Section>
        <Section theme="default">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8 text-center">
              <p className="heading-xl color-black">Frequently asked questions</p>
            </div>
          </div>
          <div className="row justify-content-center" style={{ marginTop: '3rem' }}>
            <div className="col-lg-10 col-xl-8">
              <FAQCard title={ `Who can join the program?` }>
                <p className="text-body color-black">Our Affiliate Program is available to all Interact users or those who wish to educate people on using Interact software and take a piece of the pie for bringing on new business to the platform.</p>
              </FAQCard>
              <FAQCard title={ `What is the criteria for a qualifying customer?` }>
                <p className="text-body color-black">We consider a qualified customer to be any customer who has not previously signed up for Interact, has an active subscription, and is current with their payment.</p>
              </FAQCard>
              <FAQCard title={ `How do I get paid?` }>
                <p className="text-body color-black">Payments for sales are issued once a month through a payout of your choice. <a href="https://support.partnerstack.com/hc/en-us/articles/360009501113-Payouts-101" className="color-pink">Payments 101</a></p>
              </FAQCard>
              <FAQCard title={ `How do I reset my PartnerStack password?` }>
                <p className="text-body color-black"><a href="https://support.partnerstack.com/hc/en-us/articles/360009314173-How-do-I-reset-my-password-partner-portal-" className="color-pink">Click here</a> to learn how to reset your password.</p>
              </FAQCard>
              <FAQCard title={ `How do I navigate the PartnerStack portal?` }>
                <p className="text-body color-black"><a href="https://support.partnerstack.com/hc/en-us/articles/360009183474-Partner-Dashboard-101" className="color-pink">Click here</a> to find more information on how to navigate PartnerStack.</p>
              </FAQCard>
            </div>
          </div>
        </Section>
        <Section theme="purple500" style={ { padding: `5rem 0` } }>
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8 text-center">
              <p className="heading-xl color-white">Get started today</p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xl-6 text-center">
              <p className="heading-sm color-white text-center" style={{ marginTop: '0', marginBottom: '2rem' }}>Use our AI quiz maker to create a beautiful quiz for your brand in a minute.</p>
              <Button theme="white" size="large" href="https://ai.tryinteract.com/create">Make a quiz - for free</Button>
            </div>
          </div>
        </Section>
      </main>
      <Footer/>
    </>
  )
}

const FAQCard = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <button className={ styles.faqBtn } onClick={ () => setOpen(!open) }>
        <span>{ props.title }</span>
        { !open ? <FontAwesomeIcon icon={ faPlus } /> : <FontAwesomeIcon icon={ faMinus } /> }
      </button>
      <div className={ [styles.collapse, (open ? styles.show : '')].join(' ') }>
        <div className={ styles.content }>{ props.children }</div>
      </div>
    </>
  );
};

export default AffiliatesPage;